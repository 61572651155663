import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap,  CSectTitle, CHeroImg, CDefinition, CSpSliderCard, CContentBox } from "../../../components/_index"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: <>GROUP WOKATION</>,
            ja: 'グループワーケーション'
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/workation/kv.png'
              },
              imgSp: {
                src: '/assets/images/banquet/workation/kv__sp.png'
              }
            }
          ]
        }}
      >
        <CBreadCrumb data={{
          parent: [
            {
              label: '宴会・会議',
              path: "/banquet/"
            }
          ],
          current: {
            label: 'グループワーケーション'
          }
        }} />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">遊ぶように働く<br />生産性を高めるリゾートワーク</h2>
            <p className="c_sectLead u_mb50">仙台市内とは思えないロケーション、<br />ホテル品質のサービスと美味しい料理、充実のファシリティで快適に仕事ができる環境をご提供いたします。<br />会社や部署単位でのご利用はもちろん、少人数でのご利用も可能です。</p>
            <CHeroImg img={{
              src: "/assets/images/banquet/workation/img_hero.png",
              alt: ""
            }} />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle title={{
              ja: "アクティビティ概要",
              en: <>ACTIVITY <br className="u_sp" />SUMMARY</>
            }} />
            <CDefinition data={[
              {
                title: <>期間</>,
                text: <>通年</>
              },
              {
                title: <>ご利用可能人数</>,
                text: <>
                少人数から大規模なものまでご相談ください<br />
                <ul className="c_noteList u_mt5">
                  <li>掲載の内容は予告なく変更する場合があります。</li>
                  <li>画像はイメージです。</li>
                </ul>
                </>
              },
            ]} />
            <CSpSliderCard data={[
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary.png",
                  alt: ""
                }
              },
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary02.png",
                  alt: ""
                }
              },
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary03.png",
                  alt: ""
                }
              },
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary04.png",
                  alt: ""
                }
              },
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary05.png",
                  alt: ""
                }
              },
              {
                img: {
                  src: "/assets/images/banquet/workation/img_summary06.png",
                  alt: ""
                }
              },
            ]} />
            <CContentBox>
              <h3 className="c_headingLv3 u_tac u_mb20">お問合せについて</h3>
              <p className="c_sectLead u_mb25">開放的な環境から生まれる斬新なアイディアやイノベーション、<br />闊達なコミュニケーションが生まれる様々なアクティビティをご用意しております。<br />詳しくは下記よりお問合せください。</p>
              <p className="u_tac"><a href="tel:0223772111" className="c_tel"><i className="icon-tel" />022-377-2111</a></p>
            </CContentBox>
          </LWrap>
        </section>
      </div>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`